import ordersRoutes from './routes/orders.routes'
import orderRoutes from './routes/order.routes'
import deliveryRequestRoutes from './routes/delivery.request.routes'
import appsRoutes from './routes/apps.routes'

const Onboard = () => import('../pages/Onboard.vue')
const DRServicePoint = () => import('../pages/widgets/DRServicePoint')
const ShopifyCheckout = () => import('../pages/integrations/shopify/widgets/Checkout')
const NotFound = () => import('../pages/NotFound')
const Unauthorized = () => import('../pages/Unauthorized')
const Shopify = () => import('../pages/settings/Shopify')
const Sender = () => import('../pages/settings/Sender')
const App = () => import('../pages/App.vue')
const AppAmazon = () => import('../pages/apps/Amazon.vue')
const AppEtsy = () => import('../pages/apps/Etsy.vue')
const AppEbay = () => import('../pages/apps/Ebay.vue')
const AppShopify = () => import('../pages/apps/Shopify.vue')
const ShippingPlans = () => import('../pages/wms/ShippingPlans.vue')
const ShippingPlan = () => import('../pages/wms/ShippingPlan.vue')
const AppQuickBooks = () => import('../pages/apps/QuickBooks.vue')
const DMRequestLegacy = () => import('../pages/delivery-manager/DMRequestLegacy')


export const rawSimpleRoutes = [
  {
    entityClass: '',
    path: '/',
    icon: 'home',
    name: 'Onboard',
    component: Onboard,
    noChild: true,
    children: [
      {
        entityClass: 'Orderadmin\\DeliveryServices\\Entity\\Sender',
        path: '/sender/entity/:id',
        component: Sender
      },
      {
        entityClass: 'Orderadmin\\Integrations\\Shopify\\Service\\Integration',
        path: '/source/entity/:id',
        component: Sender
      },
      {
        path: 'entity/:id',
        component: App
      },
      {
        path: 'entity/amazon/:id',
        component: AppAmazon
      },
      {
        path: 'entity/etsy/:id',
        component: AppEtsy
      },
      {
        path: 'entity/ebay/:id',
        component: AppEbay
      },
      {
        path: 'entity/shopify/:id',
        component: AppShopify
      },
      {
        path: 'amazon/shipping-plans',
        component: ShippingPlans
      },
      {
        path: 'amazon/shipping-plans/:id',
        component: ShippingPlan
      },
      {
        path: 'entity/quickbooks/:id',
        component: AppQuickBooks
      },
      {
        path: 'shipments-legacy/entity/:id',
        component: DMRequestLegacy
      },
      {
        path: 'shipments-legacy/entity',
        component: DMRequestLegacy
      }
    ]
  },
  {
    entityClass: '',
    path: '/login',
    redirect: '/'
  },
  {
    entityClass: '',
    path: '/register',
    redirect: '/'
  },
  ...ordersRoutes,
  ...deliveryRequestRoutes,
  ...orderRoutes,
  ...appsRoutes
]

function createRoutes (parentRoute, routes) {
  return routes.reduce((acc, route) => {
    const updatedRoute = {
      ...route,
      name: undefined,
      children: undefined
    }

    if (parentRoute) {
      updatedRoute.path = `${parentRoute.path}/${updatedRoute.path}`
    }

    if (updatedRoute.component || updatedRoute.redirect) {
      acc.push(updatedRoute)
    }

    if (route.children) {
      acc = [...acc, ...createRoutes(updatedRoute, route.children)]
    }

    return acc
  }, [])
}

const routes = [
  ...createRoutes(undefined, rawSimpleRoutes),
  {
    path: '/shopify/:id',
    component: Shopify
  },
  // Olds
  {
    path: '/widgets/delivery-services/service-points',
    component: DRServicePoint
  },
  {
    path: '/widgets/shopify/checkout',
    component: ShopifyCheckout,
    props: {
      unAuthAccess: true,
      disableHeader: true,
      disableSidebar: true
    }
  },
  {
    path: '/unauthorized',
    component: Unauthorized,
    props: {
      disableHeader: true,
      disableSidebar: true
    }
  },
  // Not Found
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    props: {
      disableHeader: true,
      disableSidebar: true
    }
  }
]

/**
 * Get All Routes
 *
 * @returns {Array}
 */
export function getAllRoutes () {
  return routes.map(({ path }) => path).filter(path => !path.includes(':id'))
}

export default routes
